<!--补充资料上传图片-->
<template>
  <!-- 上传附件 -->
  <div class="page-upload-img">

    <ul>
      <li v-for="(item, index) in photoList" class="u-box" :key="index">
        <div @click="toUpload(item.tit, item.type)" class="top">
          <img v-if="item.imgData.length > 0" :src="item.imgData[0]" alt=""/>
          <div v-else class="u-default">
            <img src="./img/addimg.png" class="u-default" alt=""/>
            <span>开始添加</span>
          </div>
        </div>
        <div class="middle">
          <img :src="item.img" alt=""/><span
        >{{ item.tit }}<i v-if="item.tit != '其他'&&item.tit != '客户照片'">(必传)</i></span
        >
        </div>
        <div class="bottom">{{ item.imgData.length }}</div>
      </li>
    </ul>

    <div class="u-footer">
      <div class="u-f-desc"></div>
      <div :class="canSubmit?'primary u-btn':'default u-btn'" @click="submit">提交</div>
    </div>

  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import { Jump } from '@/utils/routh'
import API from '@/server/api'
import { Toast } from 'vant'
import { useRouter } from 'vue-router'

export default {
  setup () {
    const state = reactive({
      fileList: [],
      creditNo: '',
      photoList: [],
      isLoading: false,
      canSubmit: false
    })

    const Router = useRouter()

    // 点击跳转到上传图片的页面
    const toUpload = (name, type) => {
      Jump('/upload', {
        everyName: name,
        type
      })
    }

    // 初始化信息
    const init = async () => {
      try {
        const res = await API.getPhoto({ creditNo: state.creditNo, type: 'H5' })
        if (res.success) {
          state.photoList = res.data
          let canSubmit = true
          res.data.forEach(item => {
            switch (item.type) {
              case 'alipay':
              case 'shouru':
              case 'shoushu':
                if (!item.imgData.length) canSubmit = false
                break
            }
          })
          state.canSubmit = canSubmit
        }
        console.log(state.photoList, '999')
      } catch (err) {
        Toast(err.message)
        console.log(err.message)
      }
    }

    // 点击提交
    const submit = async () => {
      let bool = true
      for (const n in state.photoList) {
        if (!state.photoList[n].imgData.length && state.photoList[n].tit !== '其他' && (!state.photoList[n].imgData.length && state.photoList[n].tit !== '客户照片')) {
          bool = false
        }
      }

      if (bool) {
        const res = await API.submitPhoto({ creditNo: state.creditNo })
        if (res.success) {
          Toast.success('提交成功')
          setTimeout(() => {
            Router.back()
          }, 1000)
        }
      } else {
        Toast('必填的资料请上传完整')
      }
    }

    onMounted(async () => {
      state.creditNo = localStorage.getItem('creditNo')
      await init()
    })

    return {
      ...toRefs(state),
      toUpload,
      submit
    }
  }
}
</script>

<style lang="less" src="./index.less" scoped></style>
